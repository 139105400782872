/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import cx from 'classnames';
import * as React from 'react';
import styles from './Dialog.module.scss';
import { Typography } from '@/components/Typography';

type Props = {
  className: string;
  ariaLabel?: string;
  id?: string;
  children: React.ReactNode;
  typographyComponent?: React.ElementType;
};

export const DialogTitle = (props: Props) => {
  const { children, className, id, ariaLabel, typographyComponent } = props;

  return (
    <Typography
      component={typographyComponent || 'h2'}
      className={cx(styles.dialogTitleRoot, className)}
      id={id}
      ariaLabel={ariaLabel}
    >
      {children}
    </Typography>
  );
};
