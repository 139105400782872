/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { pascalCase } from '../../utils/pascalCase';
import styles from './Typography.module.scss';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline'
  | 'srOnly'
  | 'inherit';

type Props1 = {
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLElement>) => void;
  onMouseEnter: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
} & Props;

type Props2 = {
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  onMouseLeave: (event: React.MouseEvent<HTMLElement>) => void;
} & Props;

type Props3 = {
  onFocus: (event: React.FocusEvent<HTMLElement>) => void;
  onMouseEnter: (event: React.MouseEvent<HTMLElement>) => void;
} & Props;

type Props = {
  /**
   * Set the text-align on the component.
   */
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * @ignore
   */
  className?: string;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   * Overrides the behavior of the `variantMapping` prop.
   */
  component?: React.ElementType;
  /**
   * Controls the display type
   */
  display?: 'initial' | 'block' | 'inline';
  /**
   * If `true`, the text will have a bottom margin.
   */
  gutterBottom?: boolean;
  /**
   * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
   *
   * Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   */
  noWrap?: boolean;
  /**
   * If `true`, the text will have a bottom margin.
   */
  paragraph?: boolean;
  /**
   * Label for screen readers.
   */
  ariaLabel?: string;
  /**
   * Applies the theme typography styles.
   */
  variant?: Variant;
  /**
   * The component maps the variant prop to a range of different HTML element types.
   * For instance, subtitle1 to `<h6>`.
   * If you wish to change that mapping, you can provide your own.
   * Alternatively, you can use the `component` prop.
   */
  variantMapping?: { [key in Variant]: string };
  id?: string;
  'aria-haspopup'?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  tabIndex?: string;
};

// @ts-expect-error refine type
const defaultVariantMapping: { [key in Variant]: string } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
};

export const Typography = React.forwardRef<HTMLElement, Props | Props1 | Props2 | Props3>(
  function Typography(props, ref) {
    const {
      align = 'inherit',
      className,
      color = 'initial',
      component,
      display = 'initial',
      gutterBottom = false,
      noWrap = false,
      paragraph = false,
      variant = 'body1',
      variantMapping = defaultVariantMapping,
      id,
      ariaLabel,
      ...other
    } = props;
    const Component =
      component ||
      (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant]) ||
      'span';
    return (
      <Component
        className={cx(
          styles.root,
          {
            [styles[variant]]: variant !== 'inherit',
            [styles[`color${pascalCase(color)}`]]: color !== 'initial',
            [styles.noWrap]: noWrap,
            [styles.gutterBottom]: gutterBottom,
            [styles.paragraph]: paragraph,
            [styles[`align${pascalCase(align)}`]]: align !== 'inherit',
            [styles[`display${pascalCase(display)}`]]: display !== 'initial',
          },
          className
        )}
        ref={ref}
        id={id}
        aria-label={ariaLabel}
        {...other}
      />
    );
  }
);
